import React from 'react';
import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { AdminLayout } from '@components/Admin/AdminLayout';

const Customers: NextPage = () => {
  const router = useRouter();
  const { uid } = router.query;
  return (
    <AdminLayout>
      <>
        <iframe
          src={uid ? `/widgets/admin/index.html#/users/${uid}` : '/widgets/admin/index.html'}
          title='Embedded App'
          style={{ width: '100%', height: '1000px', border: 'none' }}
        />
      </>
    </AdminLayout>
  );
};

export default Customers;
