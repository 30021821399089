import React, { useContext } from 'react';
import { UseStyles } from 'hooks';
import { HeaderDefault } from '@components/Header';
import { UnauthenticatedLayout } from 'components/HomeLayout';
import AppshellContext from '@inc/AppshellContext';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import { themeDark } from '../../../theme';
import { useRouter } from 'next/router';
import Link from 'next/link';
import $account from 'services/Account';
import {
  WeSaluteLogo,
  MemberIcon,
  IcPeopleIcon,
  VerificationIcon,
  ProgramsIcon,
  ReceiptListIcon,
  CheckedEnvelope,
} from 'theme/icons';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

type LayoutType = {
  children?: JSX.Element;
  back?: { url: string; title: string };
};

type SidebarIconType = {
  label: string;
  icon: JSX.Element;
  href: string;
};

export function AdminLayout({ children, back }: LayoutType): JSX.Element {
  const classes = UseStyles();
  const userContext = useContext(AppshellContext);
  const user = userContext?.user?.data;
  const router = useRouter();

  const SidebarIcon = ({ label, icon, href }: SidebarIconType) => {
    return (
      <>
        <Link href={href}>
          <a className={`link ${router.pathname == href ? 'active' : ''}`}>
            {React.cloneElement(icon, {
              className: 'icon',
            })}
            {label}
          </a>
        </Link>
      </>
    );
  };

  if (!$account.roleAdmin()) {
    return (
      <div className={classes.pageContentWrapper} style={{ textAlign: 'center' }}>
        <h4>The user {user?.email} doesn't have administrator role.</h4>
      </div>
    );
  }

  return (
    <>
      {user ? (
        <ThemeProvider theme={themeDark}>
          <HeaderDefault />
          <Typography component='div' variant='body2' className={classes.adminWrapper}>
            {/* <div className={classes.adminHeader}>
              <WeSaluteLogo
                className={classes.adminHeaderLogo}
              />
              <div className={classes.adminHeaderInfo}>
                <MemberIcon className={classes.adminHeaderImg} />
                {user.firstname} {user.lastname}
              </div>
            </div> */}
            <div className={classes.adminContentWrapper}>
              <div className={classes.adminContentInner}>
                {/*Todo: display adminSidebarNav when the functionality is ready for use by users with admin rights.*/}
                <div className={classes.adminSidebar}>
                  {back && (
                    <div className={classes.adminSidebarNavBack}>
                      <SidebarIcon icon={<ArrowBackIosIcon />} label={back.title} href={back.url} />
                    </div>
                  )}
                  <nav className={classes.adminSidebarNav}>
                    <SidebarIcon icon={<IcPeopleIcon />} label='Customers' href='/admin/consumer/customers' />
                    {/* <SidebarIcon icon={<VerificationIcon />} label='Verification' href='/admin/verification' /> */}
                    {/* <SidebarIcon icon={<ProgramsIcon />} label='Programs' href='/admin/programs' /> */}
                    {/* <SidebarIcon icon={<ReceiptListIcon />} label='Invoices' href='/admin/invoices' /> */}
                    <SidebarIcon icon={<IcPeopleIcon />} label='Users' href='/users' />
                    <SidebarIcon icon={<CheckedEnvelope />} label='Fulfillment' href='/fulfillment/backlog' />
                  </nav>
                </div>
                <div className={classes.adminContent}>{children}</div>
              </div>
            </div>
          </Typography>
        </ThemeProvider>
      ) : (
        <UnauthenticatedLayout />
      )}
    </>
  );
}
